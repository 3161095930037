<iframe
  style="
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    margin: 0;
  "
  src="https://www.canva.com/design/DAGNGfHzsrg/kNK6hZODI8dqgox4QOPRkA/view?embed"
  allowfullscreen="allowfullscreen"
  allow="fullscreen"
></iframe>
