import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'credentials-regions-cl-es',
  templateUrl: `./credentials-regions-cl-es.component.html`,
  styleUrls: ['./credentials-regions-cl-es.component.css']
})
export class CredentialsRegionsClEsComponent implements OnInit {

  public url?: string | SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, title: Title) {
    this.sanitizer = sanitizer;
    title.setTitle('Credenciales Eglow');
  }

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.urlCredencialsCanvas);
  }

}
